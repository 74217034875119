import React, { useState, useEffect } from 'react';

const AdComponent = () => {
  // Array of ad iFrames
  const ads = [
    `<div class="ad-container1"><iframe id='a0d70b04' name='a0d70b04' src='https://resources.lewdmuses.com/www/delivery/afr.php?zoneid=4&amp;cb=${Math.random() * 99999999999}' frameborder='0' scrolling='no' width='300' height='200' allow='autoplay'><a href='https://resources.lewdmuses.com/www/delivery/ck.php?n=a9111278&amp;cb=${Math.random() * 99999999999}' target='_blank'><img src='https://resources.lewdmuses.com/www/delivery/avw.php?zoneid=4&amp;cb=${Math.random() * 99999999999}&amp;n=a9111278' border='0' alt='' /></a></iframe></div>`,
    `<div class="ad-container"><iframe id='a6be6051' name='a6be6051' src='https://resources.lewdmuses.com/www/delivery/afr.php?zoneid=2&amp;cb=${Math.random() * 99999999999}' frameborder='0' scrolling='no' width='300' height='250' allow='autoplay'><a href='https://resources.lewdmuses.com/www/delivery/ck.php?n=ae9ce873&amp;cb=${Math.random() * 99999999999}' target='_blank'><img src='https://resources.lewdmuses.com/www/delivery/avw.php?zoneid=2&amp;cb=${Math.random() * 99999999999}&amp;n=ae9ce873' border='0' alt='' /></a></iframe></div>`,
    `<div class="ad-container"><iframe id='ae038fac' name='ae038fac' src='https://resources.lewdmuses.com/www/delivery/afr.php?zoneid=3&amp;cb=${Math.random() * 99999999999}' frameborder='0' scrolling='no' width='336' height='280' allow='autoplay'><a href='https://resources.lewdmuses.com/www/delivery/ck.php?n=a609c1f1&amp;cb=${Math.random() * 99999999999}' target='_blank'><img src='https://resources.lewdmuses.com/www/delivery/avw.php?zoneid=3&amp;cb=${Math.random() * 99999999999}&amp;n=a609c1f1' border='0' alt='' /></a></iframe></div>`
  ];

  // Randomly pick one ad iFrame
  const [selectedAd, setSelectedAd] = useState(ads[Math.floor(Math.random() * ads.length)]);

  useEffect(() => {
    // Every time the component mounts, a new ad is selected
    setSelectedAd(ads[Math.floor(Math.random() * ads.length)]);
  }, []);

  return (
    <div dangerouslySetInnerHTML={{ __html: selectedAd }} />
  );
};

export default AdComponent;
